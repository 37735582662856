import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { ChartData } from '../types';

interface ReturnDeltasChartProps {
  jsonData: ChartData;
}

const ReturnDeltasChart: React.FC<ReturnDeltasChartProps> = ({ jsonData }) => {
  if (!jsonData || !jsonData.return_deltas || !jsonData.return_deltas.transitions) return null;

  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  // Simplified to work with just delta and timestamp
  const transitions = jsonData.return_deltas.transitions.map(t => ({
    x: new Date(t.timestamp).getTime(),
    y: t.delta,
    timestamp: formatDate(t.timestamp),
    delta: t.delta.toFixed(2)
  }));

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-2 border border-gray-300 rounded shadow-md">
          <p className="text-sm font-medium">{data.timestamp}</p>
          <p className="text-sm">Delta: {data.delta} Pa</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full mt-4">
      <h3 className="text-lg font-semibold mb-8">Return Pressure Transition Deltas</h3>
      <div className="flex items-start gap-4">
        <div className="flex-1" style={{ height: '400px' }}>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={transitions}
              margin={{ top: 10, right: 30, left: 50, bottom: 60 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="x"
                type="number"
                domain={['auto', 'auto']}
                name="Time"
                tickFormatter={(tick) => {
                  const date = new Date(tick);
                  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
                }}
                label={{
                  value: 'Date',
                  position: 'insideBottom',
                  offset: -20
                }}
              />
              <YAxis
                dataKey="y"
                name="Delta"
                label={{
                  value: 'Pressure Delta (Pa)',
                  angle: -90,
                  position: 'insideLeft',
                  offset: -35
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Line
                type="monotone"
                dataKey="y"
                name="Pressure Transitions"
                stroke="#2196f3"
                dot={{ fill: '#2196f3' }}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ReturnDeltasChart;