import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer
} from 'recharts';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ChartData } from '../types';

interface CumulativeRuntimeChartProps {
  jsonData: ChartData;
}

const CumulativeRuntimeChart: React.FC<CumulativeRuntimeChartProps> = ({ jsonData }) => {
  if (!jsonData) return null;

  // Create chart data by combining runtimes with pressures (now already in hours)
  const chartData = jsonData.dates.map((date, i) => {
    const filteredIndex = jsonData.filtered_dates.indexOf(date);
    const isFiltered = filteredIndex >= 0;

    return {
      pressure: jsonData.pressures[i],
      filtered: isFiltered ? jsonData.filtered_pressures[filteredIndex] : null,
      runtime: jsonData.cumulative_runtimes[i], // Already in hours from backend
      // Calculate trend line value for this runtime
      trendRuntime: jsonData.runtime_trend_line.slope * jsonData.cumulative_runtimes[i] +
        jsonData.runtime_trend_line.intercept
    };
  });

  // Calculate max runtime with 1% buffer (already in hours)
  const maxRuntime = Math.max(...jsonData.cumulative_runtimes);
  const bufferedMax = Math.ceil(maxRuntime * 1.01);

  const formatTooltip = (value: ValueType, name: string) => {
    if (value === null || value === undefined) return ['-', name];

    if (name === 'Runtime' || name === 'Runtime Trend') {
      const hours = typeof value === 'number' ? value.toFixed(1) : value;
      return [`${hours} hrs`, name];
    }

    const val = typeof value === 'number' ? value.toFixed(2) : value;
    return [`${val} Pa`, name];
  };

  return (
    <div className="w-full mt-4">
      <h3 className="text-lg font-semibold mb-8">Pressure vs Cumulative Runtime</h3>
      <div className="flex items-start gap-4">
        <div className="flex-1" style={{ height: '400px' }}>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={chartData}
              margin={{ top: 10, right: 90, left: 50, bottom: 60 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                dataKey="runtime"
                domain={[0, bufferedMax]}
                tick={{ fontSize: 12 }}
                tickFormatter={(value) => value.toFixed(1)}
                label={{
                  value: 'Cumulative Runtime (hours)',
                  position: 'bottom',
                  offset: 40
                }}
              />
              <YAxis
                yAxisId="left"
                domain={['auto', 'auto']}
                label={{
                  value: 'Pressure (Pa)',
                  angle: -90,
                  position: 'insideLeft',
                  offset: -35
                }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                domain={['auto', 'auto']}
                label={{
                  value: 'Pressure (inH₂O)',
                  angle: 90,
                  position: 'insideRight',
                  offset: -35
                }}
                tickFormatter={(val: number) => (val * 0.004014).toFixed(2)}
              />
              <Tooltip formatter={formatTooltip} labelFormatter={(value) => `${Number(value).toFixed(2)} hrs`} />
              <Legend verticalAlign="top" height={20} />
              <ReferenceLine
                y={jsonData.targets.notice}
                stroke="orange"
                strokeDasharray="3 3"
                label={{ value: 'Notice Target', position: 'right' }}
                yAxisId="left"
              />
              <ReferenceLine
                y={jsonData.targets.change}
                stroke="red"
                strokeDasharray="3 3"
                label={{ value: 'Change Target', position: 'right' }}
                yAxisId="left"
              />
              <Line
                type="monotone"
                dataKey="pressure"
                stroke="#999"
                dot={{ r: 2 }}
                name="All measurements"
                yAxisId="left"
                strokeWidth={1}
                connectNulls={true}
              />
              <Line
                type="monotone"
                dataKey="filtered"
                stroke="#2563eb"
                dot={{ r: 2 }}
                name="Measurements Used"
                yAxisId="left"
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="trendRuntime"
                stroke="#10b981"
                strokeDasharray="5 5"
                dot={false}
                name="Runtime Trend"
                yAxisId="left"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default CumulativeRuntimeChart;